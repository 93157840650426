



















































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import html2pdf from 'html2pdf.js';
import { duplicateDataItems,
  downloadFile,
  duplicateDataItemsWithoutSelect,
  onCurrencyFocus,
  unselectFirstElement } from '@/helper/functions';
import { photovoltaicsOperatingMods, photovoltaicsTypes, PHOTOVOLTAIK_INSURANCE } from '@/constant/constants';
import DatePicker from '@/components/form/date-picker.vue';
import { dataChangeMixin } from '@/mixins/dataChangeMixin';
import apiBackend from '@/service/api-insurance';
import VersicherungsortRow from '@/views/components/photovoltaics-insurance/versicherungsort-row.vue';

const versicherungsortItem = {
  isSelected: false,
  Strabe: '',
  houseNum: '',
  PLZ: '',
  Ort: '',
  Land: '',
  ZursZone: '',
  Starkregenzone: '',
  Naturgefahren2: '',
  Naturgefahren3: '',
};
const byVersicherungsortType = 1;

const positionsdatenItem = {
  isSelected: false,
  num: 0,
  anlage: {
    open: false,
    artDerAnlage: null,
    versicherungsort: null,
    erweiterteBeschreibung: null,
    versicherungssumme: null,
    bewertungsjahr: null,
    baujahr: (new Date()).getFullYear(),
    notizenZurAnlagen: null,
    neuheitsrabatt: null,
  },
  risikofragen: {
    open: false,
    uberschwemmung: null,
    gebaudeBauweise: null,
    blitz: null,
    lagerung: null,
    ausergewohnliche: null,
    anlageFinanziert: null,
  },
  deckungserweiterungen: {
    open: false,
    ertragsausfall: null,
    zSB: '2 Tage',
    haftzeit: '6 Monate',
    varianteZSB: null,
    varianteHaftzeit: null,
    ertragsgarantie: null,
    prognostizierzerJahresenergieertrag: null,
    zahlerstand: null,
    zahlerstandDate: '',
  },
};
const byPositionsdatenType = 2;

const variantItem = {
  sb: null,
  sBrabattfaktor: null,
  tarifpramiensatzAktuellNetto: null,
  tarifpramiensatz371Netto: null,
  tarifpramieNetto: null,
};

const erstrisikosummenAdditionalItem = {
  name: '',
  ERS: null,
  GeanderteERS: null,
};

const byErstrisikosummenType = 3;

const copyObject = (data: any) => JSON.parse(JSON.stringify(data));

export default Vue.extend({
  name: 'photovoltaics-insurance-EEPV-ABPV',
  mixins: [dataChangeMixin],
  components: {
    DatePicker,
    VersicherungsortRow,
  },
  data() {
    return {
      name: 'photovoltaics-insurance-EEPV-ABPV',
      backUrl: 'photovoltaics-insurance-EEPV',
      key: PHOTOVOLTAIK_INSURANCE,
      photovoltaicsTypes,
      photovoltaicsOperatingMods,
      nextTabId: 5,
      submitedForm: false,
      versicherungsortItems: [
        {
          ...copyObject(versicherungsortItem),
        },
      ],
      positionsdatenItems: [
        {
          ...copyObject(positionsdatenItem),
        },
      ],
      selbstbeteiligung: {
        tarif: {
          pos: 'Tarif-SB',
          ...copyObject(variantItem),
        },
        variant1: {
          pos: 'SB-Variante 1',
          ...copyObject(variantItem),
        },
        variant2: {
          pos: 'SB-Variante 2',
          ...copyObject(variantItem),
        },
        variant3: {
          pos: 'SB-Variante 3',
          ...copyObject(variantItem),
        },
      },
      erstrisikosummen: {
        BaugrundBodenmassen: {
          name: 'Aufräumungs-, Bergungs-, Dekontaminations- und Entsorgungskosten',
          ERS: 50000,
          GeanderteERS: null,
        },
        HilfsbautenUndBauhilfsstoffe: {
          name: 'Dekontaminations- und Entsorgungskosten für Erdreich',
          ERS: 50000,
          GeanderteERS: null,
        },
        ArbeitsUndFasadengeruste: {
          name: 'Bewegungs- und Schutzkosten',
          ERS: 50000,
          GeanderteERS: null,
        },
        BautafelERS: {
          name: 'Luftfrachtkosten',
          ERS: 50000,
          GeanderteERS: null,
        },
        LokalisierungSchadenurs: {
          name: 'Bergungskosten',
          ERS: 50000,
          GeanderteERS: null,
        },
        ZusatzlicheAufraumkosten: {
          name: 'Kosten für Erd-, Pflaster-, Mauer- und Stemmarbeiten',
          ERS: 50000,
          GeanderteERS: null,
        },
      },
      erstrisikosummenAdditionalItems: [] as any,
      byVersicherungsortType,
      byPositionsdatenType,
      byErstrisikosummenType,
      pdfStyles: false,
      deductibleVariantsCssClasses: {
        tarif: '',
        variant1: 'table-variant-1',
        variant2: 'table-variant-2',
        variant3: 'table-variant-3',
      },
      refImportFileName: 'excelImport',
    };
  },
  props: ['insuranceKey', 'caseStatus', 'insuranceData', 'id', 'productId', 'processSavingData', 'priceData'],
  watch: {
    processSavingData(value: boolean) {
      if (this.insuranceData && this.insuranceData.basicData && this.firstOpenTab) {
        this.fillVersicherungsortItemsFromGruppendaten(this.insuranceData.basicData.versicherungsort);
      }
    },
  },
  methods: {
    onCurrencyFocus,
    goToBackUrl() {
      this.$emit('go-back-tab', this.backUrl, 'insuranceForms', 3);
    },
    fillVersicherungsortItemsFromGruppendaten(versicherungsort: any) {
      if (!this.versicherungsortItems[0].Strabe) {
        this.versicherungsortItems[0].Strabe = versicherungsort.Strabe;
      }

      if (!this.versicherungsortItems[0].PLZ) {
        this.versicherungsortItems[0].PLZ = versicherungsort.PLZ;
      }

      if (!this.versicherungsortItems[0].Ort) {
        this.versicherungsortItems[0].Ort = versicherungsort.Ort;
      }
      if (!this.versicherungsortItems[0].houseNum) {
        this.versicherungsortItems[0].houseNum = versicherungsort.Nr;
      }

      this.versicherungsortItems[0].ZursZone = versicherungsort.ZursZone;
      this.versicherungsortItems[0].Starkregenzone = versicherungsort.Starkregenzone;
    },
    dublicateRow(fillData: boolean, type: number) {
      switch (type) {
        case this.byVersicherungsortType:
          duplicateDataItems(versicherungsortItem, this.versicherungsortItems, fillData);
          break;
        case this.byPositionsdatenType:
          duplicateDataItemsWithoutSelect(positionsdatenItem, this.positionsdatenItems);
          break;
        case this.byErstrisikosummenType:
          duplicateDataItemsWithoutSelect(erstrisikosummenAdditionalItem, this.erstrisikosummenAdditionalItems);
          break;
        default:
          console.log('Wrong type');
      }
    },
    setValues(insuranceData: any) {
      this.versicherungsortItems = insuranceData.versicherungsortItems;
      this.setPositionsData(insuranceData.positionsdatenItems);
      this.selbstbeteiligung = insuranceData.selbstbeteiligung;
      this.erstrisikosummen = insuranceData.erstrisikosummen;
      this.erstrisikosummenAdditionalItems = insuranceData.erstrisikosummenAdditionalItems;
    },
    setPositionsData(items: any[]) {
      items.forEach((item) => {
        const addItem = copyObject(item);

        addItem.deckungserweiterungen.zahlerstandDate = new Date(item.deckungserweiterungen.zahlerstandDate);

        this.positionsdatenItems.push(addItem);
      });
    },
    onSubmit() {
      this.submitForm();
    },
    submitForm(goNextPage = true) {
      const data = {
        versicherungsortItems: this.versicherungsortItems,
        positionsdatenItems: this.positionsdatenItems,
        selbstbeteiligung: this.selbstbeteiligung,
        erstrisikosummen: this.erstrisikosummen,
        erstrisikosummenAdditionalItems: this.erstrisikosummenAdditionalItems,
      };

      this.$emit('insurance-forms-data-change', data, 'eepvAbpv', goNextPage ? this.nextTabId : false);
    },
    deleteRows(type: number) {
      let items;

      switch (type) {
        case this.byVersicherungsortType:
          unselectFirstElement(this.versicherungsortItems);

          items = this.versicherungsortItems.filter((item) => !item.isSelected);
          this.versicherungsortItems = items;
          break;
        case this.byPositionsdatenType:
          unselectFirstElement(this.positionsdatenItems);

          items = this.positionsdatenItems.filter((item) => !item.isSelected);
          this.positionsdatenItems = items;
          break;
        default:
          console.log('Wrong type');
      }
    },
    deleteErstrisikosummenItem(key) {
      this.erstrisikosummenAdditionalItems.splice(key, 1);
    },
    exportToPDF() {
      const filename = `Zusammenfassung-Photovoltaics-EEPV-ABPV-${this.id}.pdf`;
      this.pdfStyles = true;
      html2pdf(document.getElementById('element-to-convert'), {
        margin: 10,
        filename,
        html2canvas: {
          width: 1500,
        },
      }).then((response) => {
        this.pdfStyles = false;
      });
    },
    chooseImportFile() {
      this.excelImportRef.click();
    },
    uploadFile(element) {
      const file = element.target.files[0];

      if (file) {
        apiBackend.uploadFile(`${this.id}/import-places-excel`, 'import', file).then((response: any) => {
          this.importPositionsdatenFromExcel(response.data);
          this.excelImportRef.value = null;
        }).catch((e) => {
          console.error(`Error - ${e}`);
        });
      }
    },
    importPositionsdatenFromExcel(dataItems: any[]) {
      const firstElementEmpty = this.versicherungsortItems.length === 1 &&
        !this.versicherungsortItems[0].Strabe &&
        !this.versicherungsortItems[0].PLZ &&
        !this.versicherungsortItems[0].Ort &&
        !this.versicherungsortItems[0].Land;

      dataItems.forEach((item, i) => {
        const fillFirst = firstElementEmpty && i === 0;

        const newItem = fillFirst ? this.versicherungsortItems[0] : copyObject(versicherungsortItem);

        newItem.Strabe = item.street;
        newItem.PLZ = item.postCode;
        newItem.Ort = item.place;
        newItem.Land = item.land;

        if (!fillFirst) {
          this.versicherungsortItems.push(newItem);
        }
      });
    },
    downloadInsurancePlacesTemplate() {
      apiBackend.downloadInsurancePlaces().then((response) => {
        downloadFile(response.data, 'Einlesen von Versicherungsorten.xlsx');
      });
    },
    exportPlacesData() {
      const data = this.versicherungsortItems.map((itm) => ({
        street: itm.Strabe,
        code: `${itm.PLZ}`,
        city: itm.Ort,
        land: itm.Land,
      }));

      apiBackend.exportPlacesData(data).then((response) => {
        downloadFile(response.data, 'Versicherungsort-EEPV nach ABPV .xlsx');
      });
    },
  },
  mounted() {
    (this as any).resetWatcherActivated();
    if (this.insuranceData && this.insuranceData.insuranceFormsData && this.insuranceData.insuranceFormsData.eepvAbpv) {
      this.setValues(this.insuranceData.insuranceFormsData.eepvAbpv);
    }
    (this as any).startWatcherActivated();
  },
  beforeDestroy() {
    if (!this.isPCUser && !this.submitedForm) {
      this.submitForm(false);
    }
  },
  computed: {
    firstOpenTab(): boolean {
      // eslint-disable-next-line no-prototype-builtins
      return (this.insuranceData === null || this.insuranceData.insuranceFormsData === null || !this.insuranceData.insuranceFormsData.hasOwnProperty('eepv'));
    },
    showPlusBtn(): boolean {
      return (this.erstrisikosummenAdditionalItems.length < 5);
    },
    showMinusBtn(): boolean {
      return (this.erstrisikosummenAdditionalItems.length > 0);
    },
    excelImportRef(): any {
      return this.$refs[this.refImportFileName];
    },
    isPCUser(): boolean {
      return this.$store.getters['authModule/isPCUser'];
    },
  },
});
